import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const { userId } = Object.assign(props)
  const state = reactive({
    userId: userId
  })
  const fnReSend = async () => {
    const params = {}
    params.userId = state.userId
    const res = await proxy.$UserSvc.postAddEmailSend(params)
    alert(res.resultMsg)
  }
  return { ...toRefs(state), fnReSend }
}
